var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2HvRh1X9pXu3Fb-i1bmTX"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  release: '36',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],

  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
});
